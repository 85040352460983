import { Membership, CustomerInput } from '@Core/@types/skyway'

export interface CSMSelection {
  booking_for: 'recipient' | 'customer' | null
  participant: CustomerInput
}

export interface State {
  memberships?: Membership[]
  membership?: Membership
  csmSelection: CSMSelection
  selectionExpiry: string | null
}

export const defaultState = (): State => ({
  memberships: [],
  membership: undefined,
  csmSelection: {
    booking_for: null,
    participant: {
      customer_ref: '',
      first_name: '',
      last_name: '',
      date_of_birth: '',
      meta: {
        email: '',
        phone: '',
        address: {
          postal_code: '',
          street1: '',
          street2: '',
          city: '',
        },
      },
    },
  },
  selectionExpiry: null,
})

const state = defaultState

export default state
