import BaseService from '@Core/services/baseService'
import * as memberships from '@Contributions/api/queries/memberships.gql'

export default class MembershipsService extends BaseService {
  async getMemberships(): Promise<any> {
    const response = await this.client.query({
      query: memberships.getMemberships,
    })
    return this.respond(response)
  }

  async getMembershipByRef(id: number): Promise<any> {
    const response = await this.client.query({
      query: memberships.getMembershipByRef,
      variables: {
        ref: id,
      },
    })
    return this.respond(response)
  }

  async addMembership(input): Promise<any> {
    const response = await this.client.mutate({
      mutation: memberships.addMembership,
      variables: {
        fund_ref: parseInt(input.fund_ref),
        amount: input.amount,
        level_ref: parseInt(input.level_ref),
      },
    })
    return this.respond(response)
  }

  async renewMembership(input): Promise<any> {
    const response = await this.client.mutate({
      mutation: memberships.renewMembership,
      variables: {
        fund_ref: parseInt(input.fund_ref),
        amount: input.amount,
        level_ref: parseInt(input.level_ref),
        renew: true,
      },
    })
    return this.respond(response)
  }
}
