export const Pages = {
  Basket: () => import('@Basket/pages/basket').then((m) => m.default || m),
  Delivery: () => import('@Basket/pages/delivery').then((m) => m.default || m),
  Requests: () => import('@Basket/pages/requests').then((m) => m.default || m),
  Payment: () =>
    import('@Basket/pages/payment/index').then((m) => m.default || m),
  Success: () =>
    import('@Basket/pages/payment/success').then((m) => m.default || m),
  SuccessRecipientPage: () =>
    import('@Basket/pages/payment/success-recipient').then(
      (m) => m.default || m
    ),
  Cancelled: () =>
    import('@Basket/pages/payment/cancelled').then((m) => m.default || m),
  Confirmation: () =>
    import('@Basket/pages/payment/confirmation').then((m) => m.default || m),
}
