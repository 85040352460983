import { OrderItem, OrderGroup } from '@Core/@types/skyway'

export interface GroupedBasket {
  [key: string]: OrderItem[]
}

export default (arr: OrderGroup[]): GroupedBasket => {
  return arr.reduce((acc: GroupedBasket, group) => {
    const { name, items } = group
    const key = name === 'UserDefinedFee' ? 'Fees' : (name as string)
    const currGroup = acc[key] ?? []

    return { ...acc, [key]: [...currGroup, ...(items as OrderItem[])] }
  }, {})
}
