























import { Component, mixins, namespace } from 'nuxt-property-decorator'
import { HandlesBasketExpiry } from '@Basket/mixins/HandlesBasketExpiry'
import BasketExpiry from '@Basket/components/expiry/BasketExpiry.vue'
import BasketHeader from '@Basket/components/basket/BasketHeader.vue'
import HeaderPart from '@Core/components/parts/header/HeaderPart.vue'
import FooterPart from '@Core/components/parts/footer/FooterPart.vue'

const basket = namespace('basket')

@Component({
  components: {
    BasketExpiry,
    BasketHeader,
    HeaderPart,
    FooterPart,
  },
})
export default class BasketLayout extends mixins(HandlesBasketExpiry) {
  @basket.State
  protected suppress_errors!: boolean

  get breadcrumbs() {
    return [
      {
        title: 'Basket',
        href: {
          fullPath: this.$config.get('URLS').basket,
        },
      },
    ]
  }

  get showErrors() {
    return (
      this.$route.name !== 'payment' && this.$route.name !== 'success-recipient'
    )
  }

  get showBasketHeader() {
    return this.$route.name !== 'requests'
  }
}
