import { Pages } from '@Contributions/config/imports'

export const ROUTE_NAME = {
  DONATIONS: 'donations',
  DONATION: 'donation',
  MEMBERSHIPS: 'memberships',
  MEMBERSHIP: 'membership',
  CREATIVE_STUDIOS: 'creative-studios-membership',
  ADD_DONATION: 'add-donation',
  ADD_MEMBERSHIP: 'add-membership',
}

export default [
  {
    name: ROUTE_NAME.DONATIONS,
    path: '/support-us/donations',
    component: Pages.Donations,
  },
  {
    name: ROUTE_NAME.DONATION,
    path: '/donations/:id?',
    component: Pages.Donation,
  },
  // {
  //   name: ROUTE_NAME.MEMBERSHIPS,
  //   path: '/memberships',
  //   component: Pages.Memberships,
  // },
  {
    name: ROUTE_NAME.CREATIVE_STUDIOS,
    path: '/young-creatives-11-30/creative-studios-membership/join',
    component: Pages.CreativeStudiosMembership,
  },
  {
    name: ROUTE_NAME.MEMBERSHIP,
    path: '/support-us/memberships/:id?',
    component: Pages.Membership,
  },
  {
    name: ROUTE_NAME.ADD_DONATION,
    path: '/add-donation/:id?',
    component: Pages.AddDonation,
  },
  {
    name: ROUTE_NAME.ADD_MEMBERSHIP,
    path: '/add-membership/:id?',
    component: Pages.AddMembership,
  },
]
