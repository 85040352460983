import { ActionTree, ActionContext } from 'vuex'
import { RootState } from '@Core/store/types'
import { State, CSMSelection } from './state'
import types from './types'

const actions: ActionTree<State, RootState> = {
  async getMemberships(context: ActionContext<State, RootState>): Promise<any> {
    const data = await this.$memberships.getMemberships()

    context.commit(types.SET_MEMBERSHIPS, data.getMemberships)
    return data.getMemberships
  },

  async getMembershipByRef(
    context: ActionContext<State, RootState>,
    id: number
  ): Promise<any> {
    const data = await this.$memberships.getMembershipByRef(id)

    context.commit(types.SET_MEMBERSHIP, data.getMembershipByRef)
    return data.getMembershipByRef
  },

  async addMembership(
    context: ActionContext<State, RootState>,
    input
  ): Promise<any> {
    const data = await this.$memberships.addMembership(input)

    // when a membership is added to the basket we check to see if this should change the MoS
    const membershipResponse = await this.$memberships.getMembershipByRef(
      input.level_ref
    )
    const membership = membershipResponse.getMembershipByRef

    if (membership && membership.mode_of_sale_ref) {
      this.app.$eventBus.emit(
        'update_mode_of_sale',
        membership.mode_of_sale_ref
      )
    }

    this.app.$eventBus.emit('basket:item-added')

    return data.addMembership
  },

  async renewMembership(
    context: ActionContext<State, RootState>,
    input
  ): Promise<any> {
    const data = await this.$memberships.renewMembership(input)

    return data.renewMembership
  },

  // processSecondMember(context: ActionContext<State, RootState>): any {
  //   if (localStorage.secondMemberDetails) {
  //     const customer = this.getters['customer/customer']

  //     if (customer) {
  //       const second_customer = JSON.parse(localStorage.secondMemberDetails)
  //       second_customer.association.customer_ref = customer.customer_ref

  //       this.dispatch('customer/createConstituentAndAssociation', {
  //         customerInput: second_customer.customer,
  //         associationInput: second_customer.association,
  //         addressInput: Array.isArray(second_customer.customer.addresses)
  //           ? second_customer.customer.addresses[0]
  //           : null,
  //       })

  //       localStorage.removeItem('secondMemberDetails')
  //     }
  //   }
  // },

  updateSelection(
    context: ActionContext<State, RootState>,
    input: CSMSelection
  ): void {
    context.commit(types.UPDATE_SELECTION, input)
  },
  updateBookingFor(
    context: ActionContext<State, RootState>,
    input: CSMSelection
  ): void {
    context.commit(types.SET_BOOKING_FOR, input)
  },
  updateParticipant(
    context: ActionContext<State, RootState>,
    input: CSMSelection
  ): void {
    context.commit(types.UPDATE_PARTICIPANT, input)
  },
  updateMeta(
    context: ActionContext<State, RootState>,
    input: CSMSelection
  ): void {
    context.commit(types.UPDATE_META, input)
  },
  updateAddress(
    context: ActionContext<State, RootState>,
    input: CSMSelection
  ): void {
    context.commit(types.UPDATE_ADDRESS, input)
  },
  setCsmToDefault(context: ActionContext<State, RootState>): void {
    context.commit(types.SET_TO_DEFAULT)
  },
}

export default actions
