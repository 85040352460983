import { Pages } from '@Classes/config/imports'

export const ROUTE_NAME = {
  PARENT: 'workshop-id',
  CLASSES: 'classes',
  DOB: 'dob',
  DETAILS: 'details',
  APPLY: 'apply',
}

export default [
  {
    path: '/workshop/:id',
    component: Pages.Parent,
    children: [
      {
        path: '',
        component: Pages.Classes,
        name: ROUTE_NAME.CLASSES,
      },
      {
        path: 'dob',
        component: Pages.Dob,
        name: ROUTE_NAME.DOB,
      },
      {
        path: 'details',
        component: Pages.Details,
        name: ROUTE_NAME.DETAILS,
      },
      {
        path: 'apply',
        component: Pages.WorkshopApply,
        name: ROUTE_NAME.APPLY,
      },
    ],
  },
]
