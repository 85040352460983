const types = {
  SET_MEMBERSHIPS: 'SET_MEMBERSHIPS',
  SET_MEMBERSHIP: 'SET_MEMBERSHIP',
  UPDATE_SELECTION: 'UPDATE_SELECTION',
  SET_BOOKING_FOR: 'SET_BOOKING_FOR',
  UPDATE_META: 'UPDATE_META',
  UPDATE_PARTICIPANT: 'UPDATE_PARTICIPANT',
  UPDATE_ADDRESS: 'UPDATE_ADDRESS',
  SET_TO_DEFAULT: 'SET_TO_DEFAULT',
  SET_EXPIRY: 'SET_EXPIRY',
}

export default types
