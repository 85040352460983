import { ActionTree, ActionContext } from 'vuex'
import { RootState } from '@Core/store/types'
import { State, ClassBookingSelection } from './state'
import { types } from './types'

const actions: ActionTree<State, RootState> = {
  updateSelection(
    context: ActionContext<State, RootState>,
    input: ClassBookingSelection
  ): void {
    context.commit(types.UPDATE_SELECTION, input)
  },
  updateBookingFor(
    context: ActionContext<State, RootState>,
    input: ClassBookingSelection
  ): void {
    context.commit(types.SET_BOOKING_FOR, input)
  },
  updateParticipant(
    context: ActionContext<State, RootState>,
    input: ClassBookingSelection
  ): void {
    context.commit(types.UPDATE_PARTICIPANT, input)
  },
  updateMeta(
    context: ActionContext<State, RootState>,
    input: ClassBookingSelection
  ): void {
    context.commit(types.UPDATE_META, input)
  },
  updateAddress(
    context: ActionContext<State, RootState>,
    input: ClassBookingSelection
  ): void {
    context.commit(types.UPDATE_ADDRESS, input)
  },
  setToDefault(context: ActionContext<State, RootState>): void {
    context.commit(types.SET_TO_DEFAULT)
  },
}

export default actions
