import { GetterTree } from 'vuex'
import { RootState } from '@Core/store/types'
import { State } from './state'
import { Membership } from '@Core/@types/skyway'

const getters: GetterTree<State, RootState> = {
  membershipsByOrganisation:
    (state: State) =>
    (organisation: string): Membership[] => {
      return state.memberships && state.memberships.length
        ? state.memberships.filter(
            (membership) =>
              membership.membership_organisation &&
              membership.membership_organisation &&
              membership.membership_organisation.includes(organisation)
          )
        : []
    },
  csmSelection: (state: State) => {
    return state.csmSelection
  },
}

export default getters
