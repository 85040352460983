import { Vue } from 'nuxt-property-decorator'
import { MutationTree } from 'vuex'
import {
  Basket,
  BasketProperties,
  CustomerAddress,
  DeliveryMethod,
  Message,
} from '@Core/@types/skyway'
import types from './types'
import { State } from './state'

export const mutations: MutationTree<State> = {
  [types.SET_BASKET](state: State, payload: Basket): void {
    state.basket = payload
  },
  [types.SET_BASKET_EXPIRY](state: State, payload: any): void {
    state.expiry = payload
  },
  [types.SET_BASKET_PROPERTIES](state: State, payload: BasketProperties): void {
    state.properties = payload
  },
  [types.SET_PROMO_CODE](state: State, payload: string): void {
    state.promo_code = payload
  },
  [types.SET_PROMO_MODAL](state: State, payload: boolean): void {
    state.promo_modal_open = payload
  },
  [types.SET_DELIVERY_METHODS](state: State, payload: DeliveryMethod[]): void {
    state.delivery_methods = payload
  },
  [types.SET_DELIVERY_ADDRESS](state: State, payload: CustomerAddress): void {
    state.delivery_address = payload
  },
  [types.SET_BASKET_MESSAGES](state: State, payload: Message[]): void {
    state.messages = payload
  },
  [types.ADD_GIFT_VOUCHER](state: State, payload: any): void {
    if (state.gift_vouchers === undefined) {
      state.gift_vouchers = {}
    }
    state.gift_vouchers[payload.id] = payload.details
  },
  [types.REMOVE_GIFT_VOUCHER](state: State, payload: any): void {
    if (state.gift_vouchers && state.gift_vouchers[payload] !== undefined) {
      delete state.gift_vouchers[payload]
    }
  },
  [types.SET_LATEST_ORDER_ID](state: State, orderId: any): void {
    state.latest_order_id = orderId
  },
  [types.CLEAR_RECIPIENTS](state: State) {
    state.recipients = {}
  },
  [types.CLEAR_INSTANCE_RECIPIENTS](state: State, payload: string) {
    state.recipients[payload] = []
  },
  [types.SET_RECIPIENTS](state: State, payload: any) {
    state.recipients = payload
  },
  [types.SET_RECIPIENT](state: State, payload: any) {
    if (payload && state.recipients) {
      if (!state.recipients[payload.instance_ref]) {
        Vue.set(state.recipients, payload.instance_ref, [])
      }
      const index = state.recipients[payload.instance_ref].findIndex(
        (r) => r.email && r.email === payload.recipient.email
      )

      if (index === -1) {
        state.recipients[payload.instance_ref].push(payload)
      }
    }
  },
  [types.CLEAR_SPECIAL_REQUESTS](state: State) {
    state.special_requests = {}
  },
  [types.CLEAR_INSTANCE_SPECIAL_REQUESTS](state: State, payload: string) {
    state.special_requests[payload] = {}
  },
  [types.SET_SPECIAL_REQUESTS](state: State, payload: any) {
    state.special_requests = payload
  },
  [types.SET_EMERGENCY_CONTACT](state: State, payload: any) {
    state.emergency_contact = payload
  },
  [types.SET_ACCESS_REQUIREMENTS](state: State, payload: any) {
    state.access_requirements = payload
  },
  [types.SET_DEMOGRAPHICS](state: State, payload: any) {
    state.demographics = payload
  },
  [types.SET_CONSENT](state: State, payload: any) {
    state.consent = payload
  },
  [types.SET_SPECIAL_REQUEST](state: State, payload: any) {
    if (payload && state.special_requests) {
      state.special_requests[payload.instance_ref] = payload
    }
  },
  [types.SET_BASKET_FORMS](state: State, payload: Form[]): void {
    state.forms = payload
  },
  [types.SET_SUPPRESS_ERRORS](state: State, payload: boolean): void {
    state.suppress_errors = payload
  },
  [types.SET_KEY_INFO_FORM](state: State, payload: boolean): void {
    state.key_info_form = payload
  },
  [types.SET_BASKET_UPDATING](state: State, payload, boolean): void {
    state.is_updating = payload
  },
  [types.SET_DONATION_DISMISSED](state: State, payload, boolean): void {
    state.donation_dismissed = payload
  },
}

export default mutations
