













import { Component, Watch, Vue, namespace } from 'nuxt-property-decorator'
// @ts-ignore
import Timer from '~/static/images/sprites/timer.svg?inline'

const basket = namespace('basket')

@Component({
  components: {
    Timer,
  },
})
export default class BasketExpiry extends Vue {
  @basket.State
  public expiry!: string | null

  @basket.Getter
  public basketIsEmpty!: string | null

  public minutes: string = '0'
  public seconds: string = '0'
  public timer: ReturnType<typeof Timer> = 0

  get isExpired() {
    return (
      this.expiry &&
      this.$moment().utc().isAfter(this.$moment(this.expiry).utc())
    )
  }

  get isPaymentPage() {
    return this.$route.path.includes('payment')
  }

  @Watch('seconds')
  onCountdown(newVal, oldVal): void {
    if (newVal !== oldVal) {
      if (this.isExpired) {
        this.$emit('expired')
      }
    }
  }

  /**
   * The timer can get a little out of sync, check each minute
   */
  @Watch('minutes')
  resetExpiryOnMinuteChange(newVal, oldVal): void {
    if (newVal !== oldVal) {
      this.init()
    }
  }

  @Watch('expiry')
  setExpiry(): void {
    if (this.expiry) {
      const now = this.$moment().utc()
      const diff = this.$moment(this.expiry).utc().diff(now, 'seconds')

      if (this.isExpired) {
        this.$emit('expired')
        return
      }

      this.minutes = this.$moment.utc(diff * 1000).format('m')
      this.seconds = this.$moment.utc(diff * 1000).format('ss')
    }
  }

  setTimer(): void {
    clearInterval(this.timer)
    this.timer = setInterval(() => {
      const m = parseInt(this.minutes)
      const s = parseInt(this.seconds)

      if (s === 0 && m === 0) {
        if (this.isExpired) {
          this.$emit('expired')
        }
        clearInterval(this.timer)

        // new minute
      } else if (s === 0 && m > 0) {
        this.minutes = `${parseInt(this.minutes) - 1}`
        this.minutes = this.minutes.padStart(2, '')
        this.seconds = `59`

        // new second
      } else if (s > 0) {
        this.seconds = `${parseInt(this.seconds) - 1}`.padStart(2, '0')
      }
    }, 1000)
  }

  async onWindowFocusChange(e) {
    let inView = false
    if ({ focus: 1, pageshow: 1 }[e.type]) {
      if (inView) return
      await this.init()
      inView = true
    } else if (inView) {
      inView = false
    }
  }

  async init(): Promise<void> {
    await this.$store.dispatch('basket/getBasketExpiry')
    await this.$nextTick()
    if (this.expiry) {
      this.setExpiry()

      if (
        parseInt(this.minutes) > 0 ||
        (parseInt(this.minutes) === 0 && parseInt(this.seconds) > 0)
      ) {
        this.setTimer()
      }
    } else {
      this.$emit('expired')
    }
  }

  mounted() {
    this.detectFocusChange()
    try {
      this.init()
    } catch (err_) {
      console.log(err_)
    }
  }

  detectFocusChange() {
    window.addEventListener('focus', this.onWindowFocusChange)
    window.addEventListener('blur', this.onWindowFocusChange)
    window.addEventListener('pageshow', this.onWindowFocusChange)
    window.addEventListener('pagehide', this.onWindowFocusChange)
  }

  beforeDestroy() {
    window.removeEventListener('focus', this.onWindowFocusChange)
    window.removeEventListener('blur', this.onWindowFocusChange)
    window.removeEventListener('pageshow', this.onWindowFocusChange)
    window.removeEventListener('pagehide', this.onWindowFocusChange)
  }
}
