import { OrderGroup, SubOrderItem, Fee } from '@Core/@types/skyway'

type SubOrderItemWithFees = SubOrderItem & { fees: Fee[] }

const subOrderItemFeesAreCorrect = (fees: Fee[]) => {
  const unique = new Set(fees.map((f) => f.type_ref))

  if (unique.size < fees.length) {
    return false
  }

  return true
}

const checkProductFees = (groups: OrderGroup[]) => {
  let result = true

  for (const group of groups) {
    if (group.name === 'Performance' && group.items) {
      for (const item of group.items) {
        if (item && item.children) {
          for (const child of item.children as SubOrderItemWithFees[]) {
            result = subOrderItemFeesAreCorrect(child.fees)
          }
        }
      }
    }
  }

  return result
}

export { checkProductFees }
