export const Pages = {
  Donations: () =>
    import('@Contributions/pages/donations/index').then((m) => m.default || m),
  Donation: () =>
    import('@Contributions/pages/donations/_id').then((m) => m.default || m),
  Memberships: () =>
    import('@Contributions/pages/memberships/index').then(
      (m) => m.default || m
    ),
  Membership: () =>
    import('@Contributions/pages/memberships/_id').then((m) => m.default || m),
  CreativeStudiosMembership: () =>
    import('@Contributions/pages/memberships/creative-studios').then(
      (m) => m.default || m
    ),
  AddDonation: () =>
    import('@Contributions/pages/add-donation/index').then(
      (m) => m.default || m
    ),
  AddMembership: () =>
    import('@Contributions/pages/add-membership/index').then(
      (m) => m.default || m
    ),
}
