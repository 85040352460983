import { Fund } from '@Core/@types/skyway'

export interface State {
  funds?: Fund[]
  all_funds?: Fund[]
}

export const state = (): State => ({
  funds: [],
  all_funds: [],
})

export default state
