import { ActionTree, ActionContext } from 'vuex'
import { RootState } from '@Core/store/types'
import {
  Basket,
  BasketProperties,
  AddFixedPackageInput,
} from '@Core/@types/skyway'
import { sumBy } from 'lodash'
import * as basket from '@Basket/api/queries/basket.gql'
import { State } from './state'
import types from './types'
import { checkProductFees } from '@Basket/store/basket/helpers/fees'

interface basketParams {
  fetchPolicy: string
  hasSeatingConfig: boolean
}

const actions: ActionTree<State, RootState> = {
  async getBasket(
    context: ActionContext<State, RootState>,
    params: basketParams = {
      fetchPolicy: 'network-only',
      hasSeatingConfig: false,
    }
  ): Promise<Basket | false> {
    if (!process.server) {
      const query = params.hasSeatingConfig
        ? 'getBasketWithSeatingConfiguration'
        : 'getBasket'

      const response = await this.app.$apolloNonPersisted.query({
        query: basket[query],
        fetchPolicy: 'network-only',
      })

      const { data } = response

      const { groups } = (data && data[query]) || false

      if (groups) {
        if (checkProductFees(groups) === false) {
          await context.dispatch('clearBasket')
          this.app.$eventBus.notifyFailure(
            `We're sorry, there was a technical error with your order. Please go back and try again`
          )
          return false
        }
      }

      context.commit(types.SET_BASKET, data[query])

      if (data[query] && data[query].expiry) {
        context.commit(types.SET_BASKET_EXPIRY, data[query].expiry)
      }

      if (data[query] != null) {
        this.app.$eventBus.emit('update_cookie', {
          basket_count: sumBy(groups, (group) =>
            sumBy(group.items, (item) => item.children.length)
          ),
          basket_total: data[query].total,
        })
      } else {
        this.app.$eventBus.emit('update_cookie', {
          basket_count: 0,
        })
      }

      return data[query]
    } else {
      return false
    }
  },

  setBasket(
    context: ActionContext<State, RootState>,
    payload?: Basket | undefined
  ): void {
    payload
      ? context.commit(types.SET_BASKET, payload)
      : context.commit(types.SET_BASKET, undefined)
  },

  async getBasketProperties(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'network-only'
  ): Promise<BasketProperties | false> {
    if (!process.server) {
      const data = await this.$basket.getBasketProperties(fetchPolicy)

      context.commit(types.SET_BASKET_PROPERTIES, data.getBasketProperties)

      if (
        data &&
        data.getBasketProperties &&
        data.getBasketProperties.mode_of_sale_ref
      ) {
        console.log({ mode_of_sale: data.getBasketProperties.mode_of_sale_ref })
      }

      return data.getBasketProperties
    }
    return false
  },

  async getMessages(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'network-only'
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted

    const response = await client.query({
      query: basket.getMessages,
      fetchPolicy,
    })

    const { data } = response

    context.commit(types.SET_BASKET_MESSAGES, data.getMessages)
    return data.getMessages
  },

  async getBasketExpiry(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'network-only'
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted

    const response = await client.query({
      query: basket.getBasketExpiry,
      fetchPolicy,
    })

    const { data } = response

    if (data.getBasketExpiry != null) {
      this.app.$eventBus.$emit('update_cookie', {
        basket_expiry: data.getBasketExpiry,
      })
    }

    context.commit(types.SET_BASKET_EXPIRY, data.getBasketExpiry)
  },

  async getBasketComplete(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'network-only'
  ): Promise<any> {
    if (!process.server) {
      const client = this.app.$apolloNonPersisted

      const response = await client.query({
        query: basket.getBasketComplete,
        fetchPolicy,
      })

      const { data } = response

      return data.getBasketComplete
    } else {
      return false
    }
  },

  async addFixedPackages(
    context: ActionContext<State, RootState>,
    packages: any
  ): Promise<any> {
    let result = false
    for (const i in packages) {
      const pkg = packages[i]
      const input: AddFixedPackageInput = pkg
      result = await this.dispatch('basket/addFixedPackage', input)
    }
    return result
  },

  async addFixedPackage(
    context: ActionContext<State, RootState>,
    addFixedPackageInput: AddFixedPackageInput
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted
    const response = await client.mutate({
      mutation: basket.addFixedPackage,
      variables: {
        addFixedPackageInput,
      },
    })

    const { data } = response

    this.app.$eventBus.emit('basket:item-added')

    return data.addFixedPackage
  },

  async addTickets(
    context: ActionContext<State, RootState>,
    tickets: any
  ): Promise<any> {
    let result = false
    for (const i in tickets) {
      const t = tickets[i]
      if (!t.extra) t.extra = {}
      const formatted = {
        instance_ref: t.instance_ref,
        qty: t.qty,
        extra: Object.assign(t.extra, {
          price_type_ref: t.price_type_ref || t.extra.price_type_ref,
          zone_ref: t.zone_ref || t.extra.zone_ref,
          special_requests: t.special_requests ? t.special_requests : undefined,
        }),
      }
      result = await this.dispatch('basket/addTicket', formatted)
    }
    return result
  },

  async addTicket(
    context: ActionContext<State, RootState>,
    { instance_ref, qty, seats, extra }
  ): Promise<any> {
    context.commit(types.SET_BASKET_UPDATING, true)
    const client = this.app.$apolloNonPersisted
    const response = await client.mutate({
      mutation: basket.addTicket,
      variables: {
        instance_ref,
        seats,
        qty,
        extra,
      },
    })

    const { data } = response

    this.app.$eventBus.emit('basket:item-added')

    this.dispatch('basket/checkFees')
    context.commit(types.SET_BASKET_UPDATING, false)
    return data.addTicket
  },

  async removeTickets(
    context: ActionContext<State, RootState>,
    { instance_ref, li_id }
  ): Promise<any> {
    context.commit(types.SET_BASKET_UPDATING, true)
    const client = this.app.$apolloNonPersisted
    const response = await client.mutate({
      mutation: basket.removeTickets,
      variables: {
        li_id,
        instance_ref,
      },
    })

    const { data } = response

    context.commit(types.SET_BASKET_UPDATING, false)
    return data.removeTickets
  },

  async removeTicket(
    context: ActionContext<State, RootState>,
    { li_id, sli_id }
  ): Promise<any> {
    context.commit(types.SET_BASKET_UPDATING, true)
    const client = this.app.$apolloNonPersisted
    const response = await client.mutate({
      mutation: basket.removeTicket,
      variables: {
        li_id,
        sli_id,
      },
    })

    const { data } = response
    context.commit(types.SET_BASKET_UPDATING, false)
    return data.removeTicket
  },

  async removeTicketsByInstance(
    context: ActionContext<State, RootState>,
    instance_ref
  ): Promise<any> {
    context.commit(types.SET_BASKET_UPDATING, true)
    const client = this.app.$apolloNonPersisted
    const response = await client.mutate({
      mutation: basket.removeTicketsByInstance,
      variables: {
        instance_ref,
      },
    })

    if (response) {
      const { data } = response
      context.commit(types.SET_BASKET_UPDATING, false)
      return data.removeTicketsByInstance
    } else {
      context.commit(types.SET_BASKET_UPDATING, false)
      return false
    }
  },

  async removePackage(
    context: ActionContext<State, RootState>,
    { pkg_id, li_id }
  ): Promise<any> {
    context.commit(types.SET_BASKET_UPDATING, true)
    const client = this.app.$apolloNonPersisted
    const response = await client.mutate({
      mutation: basket.removeFixedPackage,
      variables: {
        pkg_id,
        li_id,
      },
    })

    const { data } = response
    context.commit(types.SET_BASKET_UPDATING, false)
    return data.removeFixedPackage
  },

  async removeContribution(
    context: ActionContext<State, RootState>,
    id
  ): Promise<any> {
    context.commit(types.SET_BASKET_UPDATING, true)
    const client = this.app.$apolloNonPersisted
    const response = await client.mutate({
      mutation: basket.removeContribution,
      variables: {
        id: parseInt(id),
      },
    })

    const { data } = response

    this.app.$eventBus.emit('contribution:removed', id)
    context.commit(types.SET_BASKET_UPDATING, false)
    return data.removeContribution
  },

  async removeMembership(
    context: ActionContext<State, RootState>,
    id
  ): Promise<any> {
    context.commit(types.SET_BASKET_UPDATING, true)
    const data = await this.$basket.removeMembership(id)

    console.log(`membershipID: ${id}`)
    if (id === 25) {
      context.commit(types.SET_KEY_INFO_FORM, true)
    }

    // make sure user has default mode of sale when membership is removed from cart
    this.app.$eventBus.emit('revert_mode_of_sale')
    this.app.$eventBus.emit('membership-offer:removed')
    context.commit(types.SET_BASKET_UPDATING, false)
    return data.removeMembership
  },

  async removeOnAccountPayment(
    context: ActionContext<State, RootState>,
    id
  ): Promise<any> {
    context.commit(types.SET_BASKET_UPDATING, true)
    const client = this.app.$apolloNonPersisted
    const response = await client.mutate({
      mutation: basket.removeOnAccountPayment,
      variables: {
        payment_id: parseInt(id),
      },
    })

    const { data } = response
    context.commit(types.SET_BASKET_UPDATING, false)
    return data.removeOnAccountPayment
  },

  async setSource(
    context: ActionContext<State, RootState>,
    id: number
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted

    const response = await client.mutate({
      mutation: basket.setSource,
      variables: {
        id,
      },
    })

    const { data } = response

    return data && data.setSource
  },

  async setModeOfSale(
    context: ActionContext<State, RootState>,
    id: number
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted

    const response = await client.mutate({
      mutation: basket.setModeOfSale,
      variables: {
        id,
      },
    })

    // retrieve basket after updating mode of sale
    setTimeout(() => {
      context.dispatch('getBasket', {
        fetchPolicy: 'network-only',
        hasSeatingConfig: true,
      })
    }, 5000)

    return response
  },

  async revertModeOfSale(
    context: ActionContext<State, RootState>
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted

    const response = await client.mutate({
      mutation: basket.revertModeOfSale,
    })

    setTimeout(() => {
      context.dispatch('getBasket', {
        fetchPolicy: 'network-only',
        hasSeatingConfig: true,
      })
    }, 2000)

    return response
  },

  async setDeliveryMethod(
    context: ActionContext<State, RootState>,
    id: number
  ): Promise<any> {
    context.commit(types.SET_BASKET_UPDATING, true)
    const client = this.app.$apolloNonPersisted

    const response = await client.mutate({
      mutation: basket.setDeliveryMethod,
      variables: {
        id,
      },
    })
    context.commit(types.SET_BASKET_UPDATING, false)

    return response
  },

  async setBillingAddress(
    context: ActionContext<State, RootState>,
    id: number
  ): Promise<any> {
    context.commit(types.SET_BASKET_UPDATING, true)
    const client = this.app.$apolloNonPersisted

    const response = await client.mutate({
      mutation: basket.setBillingAddress,
      variables: {
        id,
      },
    })

    context.commit(types.SET_BASKET_UPDATING, false)

    return response
  },

  async setDeliveryAddress(
    context: ActionContext<State, RootState>,
    id: number
  ): Promise<any> {
    context.commit(types.SET_BASKET_UPDATING, true)
    const client = this.app.$apolloNonPersisted

    const response = await client.mutate({
      mutation: basket.setDeliveryAddress,
      variables: {
        id,
      },
    })

    context.commit(types.SET_BASKET_UPDATING, false)

    return response
  },

  async getDeliveryMethods(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'network-only'
  ): Promise<DeliveryMethod[]> {
    context.commit(types.SET_BASKET_UPDATING, true)
    const client = this.app.$apolloNonPersisted
    const response = await client.query({
      query: basket.getDeliveryMethods,
      fetchPolicy,
    })

    const { data } = response

    context.commit(types.SET_DELIVERY_METHODS, data.getAvailableDeliveryMethods)
    context.commit(types.SET_BASKET_UPDATING, false)
    return data.getAvailableDeliveryMethods
  },

  async applyPromoCode(
    context: ActionContext<State, RootState>,
    code: string
  ): Promise<any> {
    context.commit(types.SET_BASKET_UPDATING, true)
    const client = this.app.$apolloNonPersisted

    const response = await client.mutate({
      mutation: basket.applyPromoCode,
      variables: {
        code,
      },
    })

    const { data } = response

    if (data && data.applyPromoCode) {
      context.commit(types.SET_PROMO_CODE, code)
    }
    context.commit(types.SET_BASKET_UPDATING, false)

    return data.applyPromoCode
  },

  async resetPromoCode(context: ActionContext<State, RootState>): Promise<any> {
    context.commit(types.SET_BASKET_UPDATING, true)
    const client = this.app.$apolloNonPersisted

    const response = await client.mutate({
      mutation: basket.resetPromoCode,
    })

    context.commit(types.SET_PROMO_CODE, '')
    context.commit(types.SET_BASKET_UPDATING, false)
    return response
  },

  async getThirdPartyContactPermissions(
    context: ActionContext<State, RootState>
  ): Promise<any> {
    context.commit(types.SET_BASKET_UPDATING, true)
    const client = this.app.$apolloNonPersisted
    const response = await client.query({
      query: basket.getThirdPartyContactPermissions,
    })

    const { data } = response

    context.commit(types.SET_BASKET_UPDATING, false)
    return data.getThirdPartyContactPermissions
  },

  async addGiftCertificate(
    context: ActionContext<State, RootState>,
    addGiftCertificateInput
  ): Promise<any> {
    context.commit(types.SET_BASKET_UPDATING, true)
    const client = this.app.$apolloNonPersisted
    const response = await client.mutate({
      mutation: basket.addGiftCertificate,
      variables: {
        addGiftCertificateInput: addGiftCertificateInput.input,
      },
    })

    const { data } = response

    if (data.addGiftCertificate !== false) {
      context.commit(types.ADD_GIFT_VOUCHER, {
        id: data.addGiftCertificate,
        details: addGiftCertificateInput,
      })
    }
    context.commit(types.SET_BASKET_UPDATING, false)
    return data.addGiftCertificate
  },

  async removeGiftCertificate(
    context: ActionContext<State, RootState>,
    gc_no
  ): Promise<any> {
    context.commit(types.SET_BASKET_UPDATING, true)
    // remove the voucher from the store so we don't send any email on order completion
    const vouchers = this.getters['basket/vouchers']
    const id = vouchers.find((v) => v.extra.certificate_reference == gc_no)
    if (id) {
      context.commit(types.REMOVE_GIFT_VOUCHER, id.item_ref)
    }

    const client = this.app.$apolloNonPersisted
    const response = await client.mutate({
      mutation: basket.removeGiftCertificate,
      variables: {
        gc_no,
      },
    })

    const { data } = response
    context.commit(types.SET_BASKET_UPDATING, false)
    return data.removeGiftCertificate
  },

  async applyGiftCertificate(
    context: ActionContext<State, RootState>,
    code
  ): Promise<any> {
    context.commit(types.SET_BASKET_UPDATING, true)
    const client = this.app.$apolloNonPersisted
    const response = await client.mutate({
      mutation: basket.applyGiftCertificate,
      variables: {
        code,
      },
    })

    const { data, errors } = response
    context.commit(types.SET_BASKET_UPDATING, false)
    return errors ? null : data.applyGiftCertificate
  },

  async unapplyGiftCertificate(
    context: ActionContext<State, RootState>,
    gc_no
  ): Promise<any> {
    context.commit(types.SET_BASKET_UPDATING, true)
    const client = this.app.$apolloNonPersisted
    const response = await client.mutate({
      mutation: basket.unapplyGiftCertificate,
      variables: {
        gc_no,
      },
    })

    const { data } = response
    context.commit(types.SET_BASKET_UPDATING, false)
    return data.unapplyGiftCertificate
  },

  async applyCreditToBasket(
    context: ActionContext<State, RootState>,
    applyCreditToBasketInput
  ): Promise<any> {
    context.commit(types.SET_BASKET_UPDATING, true)
    const client = this.app.$apolloNonPersisted
    const response = await client.mutate({
      mutation: basket.applyCreditToBasket,
      variables: {
        applyCreditToBasketInput,
      },
    })

    const { data } = response
    context.commit(types.SET_BASKET_UPDATING, false)
    return data.applyCreditToBasket
  },

  async removeCreditFromBasket(
    context: ActionContext<State, RootState>,
    payment_id
  ): Promise<any> {
    context.commit(types.SET_BASKET_UPDATING, true)
    const client = this.app.$apolloNonPersisted
    const response = await client.mutate({
      mutation: basket.removeCreditFromBasket,
      variables: {
        payment_id,
      },
    })

    const { data } = response
    context.commit(types.SET_BASKET_UPDATING, false)
    return data.removeCreditFromBasket
  },

  async clearBasket(context: ActionContext<State, RootState>): Promise<any> {
    context.commit(types.SET_BASKET_UPDATING, true)
    const client = this.app.$apolloNonPersisted
    const response = await client.mutate({
      mutation: basket.clearBasket,
      variables: {},
    })

    const { data } = response

    if (data) {
      context.commit(types.SET_KEY_INFO_FORM, true)
      context.dispatch('getBasket', 'network-only')
    }

    context.commit(types.SET_BASKET_UPDATING, false)

    return data.clearBasket
  },

  async transferBasket(context: ActionContext<State, RootState>): Promise<any> {
    context.commit(types.SET_BASKET_UPDATING, true)
    await this.dispatch('customer/transferSession', 'network-only')
    this.app.$eventBus.$emit('basket:transferred-session')
    context.commit(types.SET_BASKET_UPDATING, false)
    context.commit(types.SET_BASKET, null)
  },

  async getVerfiedDirectDebitAddresses(
    context: ActionContext<State, RootState>,
    postcode
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted
    const response = await client.query({
      query: basket.getVerfiedAddresses,
      variables: {
        postcode,
      },
    })

    const { data } = response

    return data.getVerfiedAddresses
  },

  async checkBankAccountDetails(
    context: ActionContext<State, RootState>,
    account_details
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted
    const response = await client.query({
      query: basket.checkBankAccountDetails,
      variables: {
        account_details,
      },
    })

    const { data } = response

    return data.checkBankAccountDetails
  },

  async verifyBankAccountOwnership(
    context: ActionContext<State, RootState>,
    account_owner
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted
    const response = await client.query({
      query: basket.verifyBankAccountOwnership,
      variables: {
        account_owner,
      },
    })

    const { data } = response

    return data.verifyBankAccountOwnership
  },

  async directDebitCheckout(
    context: ActionContext<State, RootState>,
    membership
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted
    const response = await client.mutate({
      mutation: basket.directDebitCheckout,
      variables: {
        membership,
      },
    })

    const { data } = response

    return data.directDebitCheckout
  },

  async directDebitCreateGiftMembershipPledge(
    context: ActionContext<State, RootState>,
    values
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted
    const response = await client.mutate({
      mutation: basket.directDebitCreateMembershipPledge,
      variables: {
        values,
      },
    })

    const { data } = response

    return data.execute
  },

  async getRelatedProductsByEventRef(
    context: ActionContext<State, RootState>,
    event_ref
  ): Promise<any> {
    context.commit(types.SET_BASKET_UPDATING, true)
    const client = this.app.$apolloNonPersisted
    const response = await client.mutate({
      mutation: basket.getRelatedProductsByEventRef,
      variables: {
        event_ref,
      },
    })

    const { data } = response
    context.commit(types.SET_BASKET_UPDATING, false)
    return data.getRelatedProductsByEventRef
  },

  async getRelatedProductsByDate(
    context: ActionContext<State, RootState>,
    date
  ): Promise<any> {
    context.commit(types.SET_BASKET_UPDATING, true)
    const client = this.app.$apolloNonPersisted
    const response = await client.mutate({
      mutation: basket.getRelatedProductsByDate,
      variables: {
        date,
      },
    })

    const { data } = response
    context.commit(types.SET_BASKET_UPDATING, false)
    return data.getRelatedProductsByDate
  },

  async completeFreeCheckout(
    context: ActionContext<State, RootState>
  ): Promise<any> {
    context.commit(types.SET_BASKET_UPDATING, true)
    const client = this.app.$apolloNonPersisted
    const response = await client.mutate({
      mutation: basket.completeFreeCheckout,
    })

    const { data } = response
    context.commit(types.SET_BASKET_UPDATING, false)
    return data.completeFreeCheckout
  },

  async updateBasketExpiry(
    context: ActionContext<State, RootState>,
    expiryTime
  ): Promise<any> {
    context.commit(types.SET_BASKET_UPDATING, true)
    const client = this.app.$apolloNonPersisted
    const response = await client.mutate({
      mutation: basket.updateBasketExpiry,
      variables: {
        expiryTime,
      },
    })

    const { data } = response
    context.commit(types.SET_BASKET_UPDATING, false)
    return data.updateBasketExpiry
  },

  async checkFees(
    context: ActionContext<State, RootState>,
    expiryTime
  ): Promise<any> {
    context.commit(types.SET_BASKET_UPDATING, true)
    const client = this.app.$apolloNonPersisted
    const response = await client.mutate({
      mutation: basket.checkFees,
    })

    const { data } = response
    context.commit(types.SET_BASKET_UPDATING, false)
    return data.checkFees
  },

  async addSpecialRequest(
    context: ActionContext<State, RootState>,
    { line_item_ref, notes }
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted
    const response = await client.mutate({
      mutation: basket.addSpecialRequest,
      variables: {
        specialRequestInput: {
          line_item_ref,
          notes,
        },
      },
    })

    const { data } = response

    return data.addSpecialRequest
  },

  async getDynamicShippingMethods(
    context: ActionContext<State, RootState>
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted
    const response = await client.query({
      query: basket.getDynamicShippingMethods,
    })

    const { data } = response

    return data.custom.response
  },

  async getBasketForms(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'network-only'
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted

    const response = await client.query({
      query: basket.getBasketForms,
      fetchPolicy,
    })

    const { data } = response

    context.commit(types.SET_BASKET_FORMS, data.getBasketForms)
    return data.getBasketForms
  },

  async loadOrder(
    context: ActionContext<State, RootState>,
    order_ref
  ): Promise<any> {
    let retried = false
    let data

    try {
      data = await this.$basket.loadOrder(order_ref)
    } catch (err_) {
      if (!retried) {
        await this.dispatch('basket/transferBasket')
        data = await this.$basket.loadOrder(order_ref)
      }
      retried = true
    }

    if (data.loadOrder && data.loadOrder.order_ref) {
      context.commit(types.SET_BASKET, data.loadOrder)
    }

    return data.loadOrder
  },
}

export default actions
