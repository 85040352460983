import { MutationTree } from 'vuex'
import types from './types'
import { State } from './state'

export const mutations: MutationTree<State> = {
  [types.SET_DONATIONS](state: State, payload: Array<Fund>): void {
    state.funds = payload
  },
  [types.SET_ALL_DONATIONS](state: State, payload: Array<Fund>): void {
    state.all_funds = payload
  }
}

export default mutations
