import { MutationTree } from 'vuex'
import { State, defaultState } from './state'
import { types } from './types'

const mutations: MutationTree<State> = {
  [types.UPDATE_SELECTION](
    state: State,
    payload: { key: string; value: string }
  ): void {
    state.selection[payload.key] = payload.value
  },
  [types.SET_EXPIRY](state: State, payload: string) {
    state.selectionExpiry = payload
  },
  [types.SET_BOOKING_FOR](state: State, payload): void {
    state.selection.booking_for = payload
  },
  [types.UPDATE_PARTICIPANT](state: State, { key, value }): void {
    state.selection.participant[key] = value
  },
  [types.UPDATE_META](state: State, { key, value }): void {
    state.selection.participant.meta![key] = value
  },
  [types.UPDATE_ADDRESS](state: State, { key, value }): void {
    state.selection.participant.meta!.address![key] = value
  },
  [types.SET_TO_DEFAULT](state: State): void {
    Object.assign(state, defaultState())
  },
}

export default mutations
