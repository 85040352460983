export const Pages = {
  Parent: () =>
    import('@Classes/pages/classes.vue').then((m) => m.default || m),
  Classes: () =>
    import('@Classes/pages/classes/index.vue').then((m) => m.default || m),
  Dob: () =>
    import('@Classes/pages/classes/dob.vue').then((m) => m.default || m),
  Details: () =>
    import('@Classes/pages/classes/details.vue').then((m) => m.default || m),
  WorkshopApply: () =>
    import('@Classes/pages/classes/apply.vue').then((m) => m.default || m),
}
