import { ActionTree, ActionContext } from 'vuex'
import { AddTicketPayload } from '@ad/gtm/dist/lib/events/ga4'
import { RootState } from '@Core/store/types'
import { State } from './state'
import types from './types'

const actions: ActionTree<State, RootState> = {
  async getFunds(context: ActionContext<State, RootState>): Promise<any> {
    const data = await this.$donations.getFunds()

    context.commit(types.SET_DONATIONS, data.getFunds)
    return data.getFunds
  },

  async getAllFunds(context: ActionContext<State, RootState>): Promise<any> {
    const data = await this.$donations.getAllFunds()

    context.commit(types.SET_ALL_DONATIONS, data.getFunds)
    return data.getFunds
  },

  async addContribution(
    context: ActionContext<State, RootState>,
    input
  ): Promise<any> {
    const data = await this.$donations.addContribution(input)

    const payload: AddTicketPayload = {
      category: 'Donations',
      instance_ref: parseInt(input.fund_ref),
      name: 'Donation',
      qty: 1,
      value: parseFloat(input.amount),
    }

    this.app.$gtmEvents.addToCart(payload)

    return data.addContribution
  },

  async removeContribution(
    context: ActionContext<State, RootState>,
    id
  ): Promise<any> {
    const data = await this.$donations.removeContribution(id)
    // this.app.$eventBus.emit('donation:removed', id)
    return data.removeContribution
  },
}

export default actions
