import { MutationTree } from 'vuex'
import types from './types'
import { State, defaultState } from './state'

export const mutations: MutationTree<State> = {
  [types.SET_MEMBERSHIPS](state: State, payload: Array<Membership>): void {
    let sorted = payload
    if (payload) {
      sorted = payload.sort((a, b) => {
        if (a.start_amount > b.start_amount) return 1
        if (a.start_amount < b.start_amount) return -1
        return 0
      })
    }
    state.memberships = sorted
  },

  [types.SET_MEMBERSHIP](state: State, payload: Membership): void {
    state.membership = payload
  },

  [types.UPDATE_SELECTION](
    state: State,
    payload: { key: string; value: string }
  ): void {
    state.csmSelection[payload.key] = payload.value
  },
  [types.SET_EXPIRY](state: State, payload: string) {
    state.selectionExpiry = payload
  },
  [types.SET_BOOKING_FOR](state: State, payload): void {
    state.csmSelection.booking_for = payload
  },
  [types.UPDATE_PARTICIPANT](state: State, { key, value }): void {
    state.csmSelection.participant[key] = value
  },
  [types.UPDATE_META](state: State, { key, value }): void {
    state.csmSelection.participant.meta![key] = value
  },
  [types.UPDATE_ADDRESS](state: State, { key, value }): void {
    state.csmSelection.participant.meta!.address![key] = value
  },
  [types.SET_TO_DEFAULT](state: State): void {
    Object.assign(state, defaultState())
  },
}

export default mutations
