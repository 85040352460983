import { GetterTree } from 'vuex'
import { RootState } from '@Core/store/types'
import { State } from './state'

const getters: GetterTree<State, RootState> = {
  selection: (state: State) => {
    return state.selection
  },
}

export default getters
