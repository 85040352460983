import { Pages } from '@Basket/config/imports'

export const ROUTE_NAME = {
  BASKET: 'basket',
  DELIVERY: 'delivery',
  REQUESTS: 'requests',
  PAYMENT: 'payment',
  SUCCESS: 'success',
  SUCCESS_RECIPIENT: 'success-recipient',
  CANCELLED: 'cancelled',
  CONFIRMATION: 'confirmation',
}

export default [
  {
    name: ROUTE_NAME.BASKET,
    path: '/basket',
    component: Pages.Basket,
  },
  {
    name: ROUTE_NAME.DELIVERY,
    path: '/delivery',
    component: Pages.Delivery,
  },
  {
    name: ROUTE_NAME.REQUESTS,
    path: '/requests/:id?',
    component: Pages.Requests,
  },
  {
    name: ROUTE_NAME.PAYMENT,
    path: '/payment',
    component: Pages.Payment,
  },
  {
    name: ROUTE_NAME.SUCCESS,
    path: '/payment/success',
    component: Pages.Success,
  },
  {
    name: ROUTE_NAME.SUCCESS_RECIPIENT,
    path: '/payment/success-recipient',
    component: Pages.SuccessRecipientPage,
  },
  {
    name: ROUTE_NAME.CANCELLED,
    path: '/payment/cancelled',
    component: Pages.Cancelled,
  },
  {
    name: ROUTE_NAME.CONFIRMATION,
    path: '/payment/confirmation',
    component: Pages.Confirmation,
  },
]
