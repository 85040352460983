






















import { Component, Vue, namespace } from 'nuxt-property-decorator'
// @ts-ignore
import InfoSvg from '~/static/images/sprites/information.svg?inline'

const basket = namespace('basket')

@Component({
  components: {
    InfoSvg,
  },
})
export default class BasketMessages extends Vue {
  @basket.State
  protected messages?: { message: string }[]

  parsedMessage(msg) {
    return msg.replace(/<\/?(?!a)(?!ul)(?!li)\w*\b[^>]*>/gm, '')
  }
}
