import { flatten } from 'lodash'

export const gtmProductsTransformer = (product_groups) => {
  const products = product_groups.map((group) => {
    const category = group.name
    return group.items.map((item) => {
      return {
        name: item.name,
        id:
          item.extra && item.extra.instance_ref
            ? item.extra.instance_ref
            : item.item_ref,
        price: item.unit_price,
        category,
        quantity: item.qty,
      }
    })
  })

  return flatten(products)
}
