import { ApolloLink } from 'apollo-link/lib/index'
import { onError } from 'apollo-link-error'
import { promiseToObservable } from '../utils'
import { ModuleOptions } from '../options'
import { TokenLink } from './token'

/**
 * Array of messages that should trigger a
 * transfer attempt
 */
const SHOULD_ATTEMPT_TRANSFER = [
  'order not found for session',
  'order is already locked in a web batch',
  'invalid order_no',
  'unable to find requested session.',
]

const getToken = (
  tokenLink: TokenLink,
  operation: any
): Promise<string | void> => {
  const oldHeaders = operation.getContext().headers || {}

  oldHeaders.Authorization = ''

  return tokenLink.fetchRefreshedToken().then((token: string): void => {
    tokenLink.setAccessToken(token)
    operation.setContext({
      headers: {
        ...oldHeaders,
        Authorization: token ? `Bearer ${token}` : null,
      },
    })
  })
}

const getStatusCode = (err): number | string => {
  if (err.message && err.message === 'Forbidden resource') {
    return 401
  }

  const code =
    err.message &&
    (err.message.statusCode ||
      (err.extensions &&
        err.extensions.exception &&
        err.extensions.exception.status) ||
      (err.extensions && err.extensions.code))

  return code
}

export class ErrorLink {
  protected ctx: any
  protected options: ModuleOptions
  protected tokenLink: TokenLink

  constructor(ctx, options) {
    this.ctx = ctx
    this.options = options
    this.tokenLink = new TokenLink(ctx, options)
  }

  getLink(): ApolloLink {
    return onError(
      ({ graphQLErrors, networkError, operation, forward, response }) => {
        const context = {
          graphQLErrors,
          networkError,
          operation,
          forward,
          response,
        }
        if (graphQLErrors) {
          for (const err of graphQLErrors) {
            console.log('APOLLO ERR:', err)
            const code = getStatusCode(err)

            if (code && code !== 'WORDPRESS_NOT_FOUND') {
              switch (code) {
                case 401:
                case 403:
                  return promiseToObservable(
                    getToken(this.tokenLink, operation)
                  ).flatMap(() => forward(operation))
                default:
                  return this.handleBadRequest(err, context)
              }
            } else if (err.extensions && err.extensions.code) {
              return this.handleBadRequest(err, context)
            }
            return this.handleBadRequest(err, context)
          }
        }

        // If there is a network error, it suggests that the connection to skyway is not available
        // In this case, retrying will only cause more problems so we log the error and do nothing else
        if (networkError) {
          console.log(
            `[APOLLO Network error]: ${networkError}`,
            'Reply: ',
            this.options.notification.NETWORK_ERROR,
            'Operation: ',
            operation.operationName
          )
          try {
            console.log(JSON.stringify(networkError, null, 2))
          } catch (err_) {
            console.log(err_)
          }
          this.ctx.app.$eventBus.notifyFailure(
            this.options.notification.NETWORK_ERROR
          )
        }
      }
    )
  }

  // GQL Validation errors must be handled on a case by case basis
  handleGqlValidationError(err, context) {
    const message = err.message.message ? err.message.message : err.message

    // this is here rather than in the switch below as the error message includes the variable ticket limit amount, so isn't an exact match
    if (
      typeof message.includes !== 'undefined' &&
      message.includes('This request will exceed the ticket limit')
    ) {
      this.ctx.app.$eventBus.notifyFailure(
        'You have exceeded the quantity allowed for this offer. Please reduce your quantity and try again.'
      )
      if (context.response && context.response.errors) {
        delete context.response.errors
      }
      return context.forward(context.operation)
    } else if (
      typeof message.includes !== 'undefined' &&
      message.includes('Unable to find requested session')
    ) {
      if (context.response && context.response.errors) {
        delete context.response.errors
      }
      return context.forward(context.operation)
    } else if (
      typeof message.includes !== 'undefined' &&
      message.includes('Could not find seats for specified criteria')
    ) {
      this.ctx.app.$eventBus.notifyFailure(
        'Warning: The number of tickets you have requested exceeds current capacity. Please reduce your ticket quantity and try again.'
      )
      if (context.response && context.response.errors) {
        delete context.response.errors
      }
      return context.forward(context.operation)
    }

    switch (message) {
      case 'GraphQL introspection is not allowed by Apollo Server, but the query contained __schema or __type. To enable introspection, pass introspection: true to ApolloServer in production':
        return context.forward(context.operation)
        break
      /**
       * This is happening on clear basket call when the basket expires
       * it prevents the notification from showing for basket expiry and
       * just shows a confusing Failed to load data message
       * Suppress it for now until we can investigate
       */
      case 'Order not found for session':
        return context.forward(context.operation)
        break
    }

    return this.handleOperationSpecificError(context, message)
  }

  handleBadRequest(err, context) {
    switch (err.extensions.code) {
      case 'TESSITURA_SEAT_LOCKING_ERROR':
        return promiseToObservable(
          this.handleSeatLockingError(err, context)
        ).flatMap(() => context.forward(context.operation))
      case 'PERSISTED_QUERY_NOT_FOUND':
      case 'WORDPRESS_UNAUTHORIZED':
      case 'WORDPRESS_NOT_FOUND':
        return context.forward(context.operation)
      case 'GRAPHQL_VALIDATION_FAILED':
      case 'TESSITURA_BAD_REQUEST':
      case 'TESSITURA_SERVER_ERROR':
        let msg
        try {
          msg =
            typeof err.message == 'string' ? err.message : err.message.message
        } catch (err_) {
          msg = ''
        }

        if (
          SHOULD_ATTEMPT_TRANSFER.find((poss) => {
            return msg.toLowerCase().includes(poss.toLowerCase())
          })
        ) {
          return promiseToObservable(
            this.handleInvalidOrderError(err, context)
          ).flatMap(() => context.forward(context.operation))
        } else {
          return this.handleGqlValidationError(err, context)
        }
      default:
        return this.handleOperationSpecificError(context, err.message)
    }
  }

  handleOperationSpecificError(context, msg = '') {
    // Surpress notifications triggered by key/val service
    if (
      context.operation.operationName &&
      context.operation.operationName.toLowerCase().includes('keyvalue')
    ) {
      if (context.response && context.response.errors) {
        delete context.response.errors
      }

      return context.forward(context.operation)
    }
    let str =
      this.options.notification.GQL_ERROR[msg] ||
      'Sorry, there was a problem connecting to our box office. Please try your selection again'

    if (!str) {
      str = this.options.notification.GQL_ERROR[context.operation.operationName]
    }

    this.ctx.app.$eventBus.notifyFailure(
      str || this.options.notification.GQL_ERROR.generic
    )

    if (context.response && context.response.errors) {
      delete context.response.errors
    }

    return context.forward(context.operation)
  }

  handleSeatLockingError(err, context): Promise<any> {
    const urlParams = new URLSearchParams(window.location.search)
    if (!urlParams.has('transfer')) {
      return this.ctx.store
        .dispatch(`${this.options.storeNamespace}/transferSession`)
        .then(() => {
          try {
            return context.forward(context.operation)
          } catch (err) {
            if (process.client) {
              if (!urlParams.has('transfer')) {
                this.handleClientRedirect('transfer=1')
              }
            }
          }
        })
    }
    return context.forward(context.operation)
  }

  handleClientRedirect(newParam: string) {
    if (process.client) {
      const currentUrl = window.location.href
      const hasQueryString = currentUrl.includes('?')
      const newUrl =
        currentUrl + (hasQueryString ? `&${newParam}` : `?${newParam}`)
      window.location.href = newUrl
    }
  }

  handleInvalidOrderError(err, context): Promise<any> {
    const urlParams = new URLSearchParams(window.location.search)
    if (!urlParams.has('transfer')) {
      return this.ctx.store
        .dispatch(`${this.options.storeNamespace}/transferSession`)
        .then(() => {
          if (process.client) {
            if (!urlParams.has('transfer')) {
              this.handleClientRedirect('transfer=1')
            }
          }
          return context.forward(context.operation)
        })
    }
    return context.forward(context.operation)
  }
}
