import {
  Basket,
  DeliveryMethod,
  BasketProperties,
  Message,
  CustomerAddress,
  Form,
} from '@Core/@types/skyway'
import { Recipient } from './types'

export interface BasketSeatDetails {
  row: String
  number: String
  seat_type: String
  seat_ref: String
  screen_ref: String
}
export interface BasketSeat {
  price: String
  instance_ref: String
  item_ref: String
  sub_item_ref: String
  seat: BasketSeatDetails
}

export interface State {
  basket?: Basket
  expiry?: any
  groups?: any
  delivery_methods?: DeliveryMethod[]
  delivery_address?: CustomerAddress
  properties?: BasketProperties
  promo_code?: string
  promo_modal_open: boolean
  messages?: Message[]
  gift_vouchers?: object
  latest_order_id?: string
  recipients: { [key: string]: Recipient[] }
  special_requests: object
  emergency_contact?: object
  access_requirements?: object
  demographics?: object
  consent?: object
  forms?: Form[]
  suppress_errors: boolean
  key_info_form: boolean
  is_updating: boolean
  donation_dismissed: boolean
}

export const state = (): State => ({
  basket: undefined,
  groups: undefined,
  expiry: undefined,
  delivery_methods: undefined,
  delivery_address: undefined,
  properties: undefined,
  promo_code: undefined,
  promo_modal_open: false,
  messages: undefined,
  gift_vouchers: undefined,
  latest_order_id: undefined,
  recipients: {},
  special_requests: {},
  emergency_contact: undefined,
  access_requirements: undefined,
  demographics: undefined,
  consent: undefined,
  forms: undefined,
  suppress_errors: false,
  key_info_form: true,
  is_updating: false,
  donation_dismissed: false,
})

export default state
