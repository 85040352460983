export const findCreativeStudioMembershipInBasket = (membershipsInBasket) => {
  let isCSM: boolean = false
  if (membershipsInBasket && membershipsInBasket.items) {
    membershipsInBasket.items.forEach((m) => {
      if (m.type === 'gift-membership') {
        isCSM = m.name.includes('Creative Studios')
      } else if (m.extra && m.extra.level_ref === 25) {
        isCSM = true
      }
    })
  }
  return isCSM
}
