import { OrderGroup, OrderItem } from '@Core/@types/skyway'

export const findRestorationLevyFees = (
  groups: OrderGroup[] & { items: OrderItem },
  levyTypeRefs: string[]
) => {
  return Array.from(groups).reduce((levyFees: any[], group) => {
    if (group.items) {
      group.items.forEach((item) => {
        if (item && item.children && item?.children.length > 0) {
          item.children.forEach((c) => {
            if (c && c.fees && c.fees.length > 0) {
              c.fees.forEach((fee) => {
                if (levyTypeRefs.includes(fee.type_ref)) {
                  levyFees.push(fee)
                }
              })
            }
          })
        }
      })
    }

    return levyFees
  }, [])
}
