import { Pages } from '@Auth/config/imports'

export const ROUTE_NAME = {
  SIGN_IN: 'sign-in',
  REGISTER: 'register',
  FORGOTTEN_PASSWORD: 'forgotten-password',
  PASSWORD_RESET: 'password-reset',
  MAGIC_LOGIN: 'magic-login',
}

export default [
  {
    name: ROUTE_NAME.SIGN_IN,
    path: '/sign-in',
    component: Pages.SignIn,
  },
  {
    name: ROUTE_NAME.REGISTER,
    path: '/register',
    component: Pages.Register,
  },
  {
    name: ROUTE_NAME.FORGOTTEN_PASSWORD,
    path: '/forgotten-password',
    component: Pages.ForgottenPassword,
  },
  {
    name: ROUTE_NAME.PASSWORD_RESET,
    path: '/password-reset/:id',
    component: Pages.PasswordReset,
  },
  {
    name: ROUTE_NAME.MAGIC_LOGIN,
    path: '/magic-login',
    component: Pages.MagicLogin,
  },
]
