


























import { Component, Vue } from 'nuxt-property-decorator'
import Back from '@UI/components/buttons/Back.vue'
import BasketMessages from '@Basket/components/basket/BasketMessages.vue'

@Component({
  components: {
    Back,
    BasketMessages,
  },
})
export default class BasketHeader extends Vue {
  get isMobile() {
    return this.$isMobile(this.$mq)
  }

  get continueText() {
    if (this.$route.path.includes('delivery')) {
      return 'Continue Shopping'
    }
    return 'Continue Browsing'
  }

  get title(): string | null {
    if (this.$route.path.includes('basket')) {
      return 'Review Basket'
    }
    if (this.$route.path.includes('delivery')) {
      return 'Checkout'
    }
    if (this.$route.path.includes('requests')) {
      return null
    }
    if (this.$route.path.includes('payment')) {
      return 'Payment'
    }
    return 'Checkout'
  }

  back() {
    if (this.$route.fullPath.includes('delivery')) {
      this.$router.push(this.$config.get('URLS').basket)
    } else {
      this.$router.back()
    }
  }
}
