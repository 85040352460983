import { Context } from '@nuxt/types'
import VueApollo, { ApolloProvider } from 'vue-apollo'
import fetch from 'node-fetch'
import { ApolloLink } from 'apollo-link'
import { HttpLink } from 'apollo-link-http'
import ApolloClient from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { createPersistedQueryLink } from 'apollo-link-persisted-queries'
import chrowdhandlerParams from '@Core/helpers/chrowdhandlerParams'
import { LogLink } from '../links/logger'
import { TokenLink } from '../links/token'
import { ErrorLink } from '../links/default'
import { ModuleOptions } from '../options'

export class Apollo {
  public ctx: Context
  public options: ModuleOptions
  public provider: ApolloProvider

  constructor(ctx: Context, options: ModuleOptions) {
    this.ctx = ctx
    this.options = options

    const tokenLink = new TokenLink(ctx, options)
    const errorLink = new ErrorLink(ctx, options)

    const crowdhandlerEvents = [
      'addTicket',
      'addTickets',
      'getBasket',
      'initiateCheckout',
      'getEventAvailability',
      'getEventPrices',
      'login',
      'addContribution',
      'setBillingAddress',
      'setDeliveryMethod',
    ]

    const customFetch = (uri, options) => {
      if (options.method === 'POST' && !process.server) {
        const { operationName } = JSON.parse(options.body)
        const crowdhandlerEvent = crowdhandlerEvents.includes(operationName)
        if (crowdhandlerEvent) {
          const params = chrowdhandlerParams(operationName)
          const fetchUri = uri + params

          return fetch(fetchUri, options)
        }
        return fetch(`${uri}?operationName=${operationName}`, options)
      }
      return fetch(uri, options)
    }

    const DefaultHttpLink = new HttpLink({
      uri: options.graphqlEndpoint,
      fetch: customFetch,
      credentials: 'omit',
    })

    const NonPersistedHttpLink = new HttpLink({
      uri: options.graphqlEndpoint,
      fetch: customFetch,
      credentials: 'omit',
    })

    const links = ApolloLink.from([
      LogLink,
      ...tokenLink.getLinks(),
      createPersistedQueryLink({ useGETForHashedQueries: true }),
      errorLink.getLink(),
      DefaultHttpLink,
    ])

    const nonPersistedLinks = ApolloLink.from([
      LogLink,
      ...tokenLink.getLinks(),
      errorLink.getLink(),
      NonPersistedHttpLink,
    ])

    const persistedClient = new ApolloClient({
      ssrMode: process.server,
      link: ctx.isDev ? nonPersistedLinks : links,
      ssrForceFetchDelay: process.browser ? 100 : 0,
      cache: new InMemoryCache(),
      connectToDevTools: process.browser,
      defaultOptions:
        typeof window === 'undefined'
          ? {
              watchQuery: {
                fetchPolicy: 'no-cache',
              },
              query: {
                fetchPolicy: 'no-cache',
                errorPolicy: 'all',
              },
            }
          : undefined,
    })

    const nonPersistedClient = new ApolloClient({
      ssrMode: process.server,
      link: nonPersistedLinks,
      ssrForceFetchDelay: process.browser ? 100 : 0,
      cache: new InMemoryCache(),
      connectToDevTools: process.browser,
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'no-cache',
          errorPolicy: 'ignore',
        },
        query: {
          fetchPolicy: 'no-cache',
          errorPolicy: 'all',
        },
        mutate: {
          errorPolicy: 'all',
        },
      },
    })

    const apolloProvider = new VueApollo({
      defaultClient:
        this.options.defaultClient == 'nonPersistedClient' || process.server
          ? nonPersistedClient
          : persistedClient,
      clients: {
        persisted: persistedClient,
        nonPersisted: nonPersistedClient,
      },
    })

    this.provider = apolloProvider
  }
}

export function createApollo(context, options) {
  const apollo = new Apollo(context, options)

  return apollo
}
