import { GetterTree } from 'vuex'
import { RootState } from '@Core/store/types'
import {
  Form,
  Payment,
  Maybe,
  OrderItem,
  OrderGroup,
  SubOrderItem,
} from '@Core/@types/skyway'
import { findRestorationLevyFees } from '@Basket/helpers/restorationLevy'
import { findCreativeStudioMembershipInBasket } from '@Basket/helpers/creativeStudioMembership'
import groupBasket from '@Basket/helpers/groupByType'
import { State, BasketSeat } from './state'

const transformRecipient = (dataObject) => {
  return {
    name: dataObject.recipient_name,
    customer_ref: dataObject.recipient_ref || false,
  }
}

const parseClassRecipients = (subOrderItems: SubOrderItem[] = []) => {
  for (const subOrderItem of subOrderItems) {
    if (subOrderItem && subOrderItem.extra) {
      const recipient = transformRecipient(subOrderItem.extra)

      if (recipient.customer_ref) {
        return recipient
      }
    }
  }
  return false
}

const parseClassPackageRecipients = (subOrderItems: SubOrderItem[] = []) => {
  if (subOrderItems.length > 0) {
    const item = subOrderItems[0]

    if (item.extra && item.extra.seats && item.extra.seats.length > 0) {
      const recipient = transformRecipient(item.extra.seats[0])
      if (recipient.customer_ref) {
        return recipient
      }
    }
  }
  return false
}

const getters: GetterTree<State, RootState> = {
  basketIsEmpty: (state: State): boolean => {
    return Boolean(
      state.basket === undefined ||
        state.basket === null ||
        (state.basket &&
          state.basket.groups &&
          state.basket.groups.length === 0 &&
          state.basket.payments &&
          state.basket.payments.length === 0)
    )
  },

  basketItemsCount: (state: State): number => {
    if (state.basket && state.basket.groups) {
      return state.basket.groups.reduce((acc, group) => {
        if (group && group.items && group.items.length > 0) {
          acc += group.items.length
        }
        return acc
      }, 0)
    } else {
      return 0
    }
  },

  contributions: (state: State) => {
    if (state.basket && state.basket.groups && state.basket.groups.length) {
      const group = state.basket.groups.find(
        (g) => g && g.name === 'Contribution'
      )
      return group && group.items ? group.items : []
    } else {
      return []
    }
  },

  groups: (state: State) => {
    if (state.basket && state.basket.groups && state.basket.groups.length) {
      return groupBasket(state.basket.groups as OrderGroup[])
    } else {
      return null
    }
  },

  loyaltyPointsRedeemable: (state: State): number => {
    return state.basket && state.basket.loyalty_points_redeemable
      ? state.basket.loyalty_points_redeemable
      : 0
  },

  loyaltyPointsEarned: (state: State): number => {
    return state.basket && state.basket.loyalty_points_earned
      ? state.basket.loyalty_points_earned
      : 0
  },

  lastAddedItemRef: (state: State, getters: GetterTree<State, RootState>) => {
    const tickets = getters.tickets

    const item_refs: any[] = []
    tickets.forEach((t) => item_refs.push(t.item_ref))

    item_refs.sort((a, b) => {
      if (a > b) return 1
      if (a < b) return -1
      return 0
    })
    return parseInt(item_refs.pop())
  },

  tickets: (state: State) => {
    if (state.basket && state.basket.groups && state.basket.groups.length) {
      const group = state.basket.groups.find(
        (g) => g && g.name === 'Performance'
      )
      return group && group.items
        ? group.items.filter(
            (i) => i && i.details && i.details.type !== 'product'
          ) || []
        : []
    } else {
      return []
    }
  },

  classes: (state: State) => {
    if (state.basket && state.basket.groups && state.basket.groups.length) {
      const group = state.basket.groups.find(
        (g) => g && (g.name === 'Performance' || g.name === 'Package')
      )
      return group && group.items
        ? group.items.filter(
            (i) =>
              (i && i.type === 'package') ||
              (i &&
                i.details &&
                (i.details.type === 'class' || i.details.type === 'education'))
          )
        : []
    } else {
      return []
    }
  },

  products: (state: State) => {
    if (state.basket && state.basket.groups && state.basket.groups.length) {
      const group = state.basket.groups.find(
        (g) => g && g.name === 'Performance'
      )
      return group && group.items
        ? group.items.filter(
            (i) => i && i.details && i.details.type === 'product'
          )
        : []
    } else {
      return []
    }
  },

  memberships: (state: State) => {
    if (state.basket && state.basket.groups && state.basket.groups.length) {
      const group = state.basket.groups.find(
        (g) => g && g.name === 'Membership'
      )

      return group && group.items ? group.items : []
    } else {
      return []
    }
  },

  vouchers: (state: State) => {
    if (state.basket && state.basket.groups && state.basket.groups.length) {
      const group = state.basket.groups.find(
        (g) => g && g.name === 'GiftCertificate'
      )

      return group && group.items ? group.items : []
    } else {
      return []
    }
  },

  payments: (state: State): Maybe<Payment>[] => {
    return state.basket && state.basket.payments ? state.basket.payments : []
  },

  seats: (
    state: State,
    getters: GetterTree<State, RootState>
  ): BasketSeat[] => {
    const seats: BasketSeat[] = []
    // @ts-ignore
    getters.tickets.forEach((ticket) => {
      if (ticket.children && ticket.children.length) {
        ticket.children.forEach((child) => {
          seats.push({
            price: child.unit_price,
            instance_ref: ticket.extra.instance_ref,
            item_ref: ticket.item_ref,
            sub_item_ref: child.sub_item_ref,
            seat: {
              row: child.extra.seat_row,
              number: child.extra.seat_number,
              seat_type: child.extra.section,
              seat_ref: child.extra.seat_ref,
              screen_ref: child.extra.section_ref,
            },
          })
        })
      }
    })

    return seats
  },

  basketSeatRefs: (state: State, getters: GetterTree<State, RootState>) => {
    // @ts-ignore
    return getters.seats.map((s) => s.seat.seat_ref)
  },

  promoCode: (state: State): string | null => {
    if (state.promo_code) {
      return state.promo_code
    } else {
      return null
    }
  },

  /**
   * We need to validate that the order doesn't contain items
   * for the logged in customer and for someone else as we can
   * only transfer the entire order over to another constituent
   */
  recipients: (state: State, getters: { classes: OrderItem[] }) => {
    const recipients: { name: string; customer_ref: string }[] = []

    if (getters.classes && getters.classes.length) {
      for (const orderItem of getters.classes) {
        if (orderItem.type === 'class' || orderItem.details?.type === 'class') {
          const recipient = parseClassRecipients(
            orderItem.children as SubOrderItem[]
          )
          recipient && recipients.push(recipient)
        } else if (orderItem.type === 'package') {
          const recipient = parseClassPackageRecipients(
            orderItem.children as SubOrderItem[]
          )
          recipient && recipients.push(recipient)
        }
      }
    }

    return recipients
  },

  forms: (state: State, getters) => {
    if (state.forms) {
      // get the instance ref associated with the relevent ticket
      state.forms.forEach((f) => {
        if (getters.tickets) {
          const ticket = getters.tickets.find(
            (t) => parseInt(t.extra.event_ref) === parseInt(f.event_ref)
          )
          if (ticket) {
            f.instance_ref = `${ticket.extra.instance_ref}`

            if (
              getters.ticketsByInstance &&
              getters.ticketsByInstance[f.instance_ref]
            ) {
              f.tickets = Object.values(
                getters.ticketsByInstance[f.instance_ref]
              ).map((t) => {
                return {
                  price_type_ref: t[0].extra.price_type_ref,
                  price_type: t[0].extra.price_type,
                  zone_ref: t[0].extra.zone_ref,
                  zone: t[0].extra.zone,
                  quantity: t.length,
                }
              })
            }
          }
        }
      })

      return state.forms
    } else {
      return null
    }
  },

  restorationLevy: (state: State) => (levyTypeRef: string) => {
    if (state.basket && state.basket.groups && state.basket.groups.length) {
      return findRestorationLevyFees(state.basket.groups, levyTypeRef)
    } else {
      return null
    }
  },

  creativeStudioMembershipInBasket: (state: State) => {
    if (state.basket && state.basket.groups && state.basket.groups.length) {
      const group = state.basket.groups.find(
        (g) =>
          (g && g.name === 'Membership') || (g && g.name === 'GiftCertificate')
      )

      return findCreativeStudioMembershipInBasket(group)
    }
  },
}

export default getters
