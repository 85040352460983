const types = {
  SET_BASKET: 'SET_BASKET',
  SET_BASKET_EXPIRY: 'SET_BASKET_EXPIRY',
  SET_DELIVERY_METHODS: 'SET_DELIVERY_METHODS',
  SET_DELIVERY_ADDRESS: 'SET_DELIVERY_ADDRESS',
  SET_BASKET_PROPERTIES: 'SET_BASKET_PROPERTIES',
  SET_PROMO_CODE: 'SET_PROMO_CODE',
  SET_PROMO_MODAL: 'SET_PROMO_MODAL',
  SET_BASKET_MESSAGES: 'SET_BASKET_MESSAGES',
  ADD_GIFT_VOUCHER: 'ADD_GIFT_VOUCHER',
  REMOVE_GIFT_VOUCHER: 'REMOVE_GIFT_VOUCHER',
  SET_LATEST_ORDER_ID: 'SET_LATEST_ORDER_ID',
  SET_RECIPIENT: 'SET_RECIPIENT',
  SET_RECIPIENTS: 'SET_RECIPIENTS',
  CLEAR_RECIPIENTS: 'CLEAR_RECIPIENTS',
  CLEAR_INSTANCE_RECIPIENTS: 'CLEAR_INSTANCE_RECIPIENTS',
  SET_SPECIAL_REQUEST: 'SET_SPECIAL_REQUEST',
  SET_SPECIAL_REQUESTS: 'SET_SPECIAL_REQUESTS',
  SET_EMERGENCY_CONTACT: 'SET_EMERGENCY_CONTACT',
  SET_ACCESS_REQUIREMENTS: 'SET_ACCESS_REQUIREMENTS',
  SET_DEMOGRAPHICS: 'SET_DEMOGRAPHICS',
  SET_CONSENT: 'SET_CONSENT',
  CLEAR_SPECIAL_REQUESTS: 'CLEAR_SPECIAL_REQUESTS',
  CLEAR_INSTANCE_SPECIAL_REQUESTS: 'CLEAR_INSTANCE_SPECIAL_REQUESTS',
  SET_BASKET_FORMS: 'SET_BASKET_FORMS',
  SET_SUPPRESS_ERRORS: 'SET_SUPPRESS_ERRORS',
  SET_KEY_INFO_FORM: 'SET_KEY_INFO_FORM',
  SET_BASKET_UPDATING: 'SET_BASKET_UPDATING',
  SET_DONATION_DISMISSED: 'SET_DONATION_DISMISSED',
}

export type Recipient = {
  first_name: String
  last_name: String
  email: String
}

export type EmergencyContact = {
  name: String
  relationship: String
  phone_number: String
}

export type AccessRequirements = {
  description: String
}

export type Demographics = {
  gender: String
  culturalOrigin: String
  employmentStatus: String
  genderOther: String
  culturalOriginOther: String
  employmentStatusOther: String
}

export type Consent = {
  imageRelease: Boolean
  isParentGuardian: Boolean
  nameOfParentGuardian: String
}

export default types
