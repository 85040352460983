import state from '@Classes/store/classes/state'
import mutations from '@Classes/store/classes/mutations'
import actions from '@Classes/store/classes/actions'
import getters from '@Classes/store/classes/getters'

export const name = 'classes'

export default {
  namespaced: true,
  name,
  state,
  actions,
  mutations,
  getters,
}
