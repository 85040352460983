import { Component, Vue, namespace } from 'nuxt-property-decorator'

const basket = namespace('basket')

@Component({})
export class HandlesBasketExpiry extends Vue {
  @basket.Action
  getBasket

  async basketExpired(): Promise<void> {
    await this.clearBasket()
    this.$eventBus.notifyFailure('Your basket has expired')

    this.$router.replace({ name: 'basket', query: { expired: 'true' } })
  }

  async clearBasket(): Promise<any> {
    await this.$store.dispatch('basket/clearBasket')
    await this.getBasket({
      fetchPolicy: 'network-only',
      hasSeatingConfig: true,
    })
    this.$forceUpdate()
  }

  handleExpiryListener() {
    this.$eventBus.notifyFailure('Sorry, your basket has expired.')
    setTimeout(() => {
      this.$router.push(this.$config.get('URLS').basket)
    }, 10000)
  }

  setExpiryListener() {
    this.$eventBus.on('basket-expired', this.handleExpiryListener)
  }

  mounted() {
    this.setExpiryListener()
  }

  beforeDestroy() {
    this.$eventBus.off('basket-expired', this.handleExpiryListener)
  }
}
